import React, { useState } from 'react';
import './App.css';

const App = () => {

  const [selectedCategory, setSelectedCategory] = useState('Depilação a Laser');

  const handleBookingClick = () => {
    document.getElementById('booking-section').scrollIntoView({ behavior: 'smooth' });
  };

  const services = [
    {
      category: 'Mulher',
      items: [
        { title: 'Corpo Completo', description: 'Tratamento de corpo completo para remover todos os pelos indesejados.', price: '65 €', time: '1 hora' },
        { title: 'Corpo Completo c/ braços e costas', description: 'Tratamento de corpo completo incluindo braços e costas.', price: '80 €', time: '1 hora' },
      ],
    },
    {
      category: 'Homem',
      items: [
        { title: 'Corpo Completo', description: 'Tratamento de corpo completo para remover todos os pelos indesejados.', price: '80 €', time: '1 hora' },
      ],
    },
    {
      category: 'Packs',
      items: [
        { title: '1 Zona', description: 'Tratamento para uma zona específica do corpo.', price: '40 €', time: '30 minutos' },
        { title: '2 Zonas', description: 'Tratamento para duas zonas específicas do corpo.', price: '55 €', time: '45 minutos' },
      ],
    },
  ];

  return (
    <div>
      {/* Section 1: Background Video and Logo */}
      <section className="relative h-screen w-full overflow-hidden flex items-center justify-center">
        <video autoPlay loop muted playsInline className="absolute w-full h-full object-cover">
          <source src="video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative z-10 text-center px-4">
          <img src="logo.png" alt="Logo" className="mx-auto mb-8" style={{ width: '250px' }} />
          <p className="text-xl mb-8" style={{ color: '#e4dcd1' }}>Descubra os benefícios da depilação a laser!</p>
          <button
            onClick={handleBookingClick}
            className="py-2 px-4 rounded bg-[#e4dcd1] text-black hover:bg-[#fff]"
          >
            Marcar Agora
          </button>
        </div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </section>

      <section id="services-section" className="py-16 bg-white">
        <h2 className="text-3xl font-bold text-center mb-8">Os nossos serviços</h2>
        <div className="text-center mb-4">
          <button
            className={`text-lg px-4 py-2 mr-2 ${selectedCategory === 'Depilação a Laser' ? 'underline' : ''}`}
            onClick={() => setSelectedCategory('Depilação a Laser')}
          >
            Depilação a Laser
          </button>
          {/* Adicione mais botões de categoria aqui conforme necessário */}
        </div>
        <div className="max-w-6xl mx-auto px-4 md:px-8 lg:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {services.map((service, idx) => (
              <div key={idx} className="mb-8">
                <h3 className="text-2xl font-bold mb-4">{service.category}</h3>
                {service.items.map((item, idx) => (
                  <div key={idx} className="mb-6">
                    <div className="flex flex-col justify-between items-start mb-2">
                      <h4 className="font-bold text-lg mb-2">{item.title}</h4>
                      <div className="flex items-center">
                        <div className="flex items-center mr-4">
                          <span className="text-gray-500 mr-1"><i className="fas fa-euro-sign text-gray-500 mr-1"></i></span>
                          <span>{item.price}</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-black-100 mx-2"><i className="far fa-clock text-black-100 mx-2"></i></span>
                          <span>{item.time}</span>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-300 mb-2"></div>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>


      <section id="booking-section" className="py-16 bg-gray-50">
        <h2 className="text-3xl font-bold text-center mb-8">Marcação Online</h2>
        <div className="flex items-center justify-center">
          <div className="w-full max-w-4xl p-8">
            <iframe src="https://buk.pt/stephanie-ferreira-beauty?header=false&amp;footer=false" title="Booking System" className="w-full h-[720px] border-none"></iframe>
          </div>
        </div>
      </section>

      <footer className="bg-gray-900 text-gray-100" style={{ backgroundColor: '#e4dcd1', color: '#fff' }}>
        <div className="w-full flex flex-col md:flex-row p-0 m-0">
          <div className="w-full md:w-1/3 mb-4 md:mb-0 text-center md:text-left p-8">
            <h3 className="text-2xl font-bold mb-4">Contactos</h3>
            <p className="mb-4">Se tiver alguma dúvida ou sugestão, contacte-nos!</p>
            <p className="mb-2">
              <strong>Stephanie Ferreira Beauty</strong><br />
              Praceta do comércio 10B, Alfragide, Portugal<br />
              +351 932 663 862
            </p>
            <div className="flex justify-center md:justify-start mt-4">
              <a href="https://www.facebook.com/dummy" className="text-gray-100 mx-2">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/stephanieferreirabeauty_/" className="text-gray-100 mx-2">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@beauty_stephanieferreira" className="text-gray-100 mx-2">
                <i className="fab fa-tiktok"></i>
              </a>
            </div>
          </div>
          <div className="w-full md:w-2/3 h-96 md:h-auto relative hidden md:block p-0 m-0">
            <div className="absolute inset-0 p-0 m-0" style={{ height: '100%', width: '100%' }}>
              <img src="map.png" alt="Map Screenshot" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            </div>
          </div>
        </div>
      </footer>



    </div>
  );
};

export default App;